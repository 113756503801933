import {
  DeleteButton,
  EditButton,
  Space,
  useTranslate,
  Table,
  useTable,
} from '@pankod/refine'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { cleanHydraId } from 'src/adapters/DataProvider'
import { CurrencyField } from 'src/components/Currency'
import { DateField } from 'src/components/DateField'
import { TitleWithActions } from 'src/components/TitleWithActions'
import type { Item, Promotion } from 'src/types/api'
import { ResourcePathEnum } from 'src/types/api'

interface Props {
  product: Item
}

export function PromotionList(props: Props) {
  const { product } = props
  const translate = useTranslate()

  const id = useMemo(() => cleanHydraId(product.id), [product.id])

  const { tableProps } = useTable<Promotion>({
    resource: ResourcePathEnum.promotions,
    permanentFilter: [
      {
        field: 'item.id',
        operator: 'eq',
        value: id,
      },
    ],
  })
  return (
    <Table
      {...tableProps}
      title={() => (
        <TitleWithActions
          title="items.fields.promotions"
          actions={
            <Link
              to={
                product.activePromotionObject
                  ? `/promotions/edit/${cleanHydraId(
                      product.activePromotionObject,
                    )}`
                  : `/promotions/create?product=${cleanHydraId(product.id)}`
              }
            >
              <EditButton onClick={undefined}>
                {translate('buttons.managePromotion')}
              </EditButton>
            </Link>
          }
        />
      )}
      rowKey="id"
      rowClassName={(promotion) => {
        if (!promotion.active) return 'AteTableRow -disabled'
        return 'AteTableRow'
      }}
    >
      <Table.Column<Promotion>
        dataIndex="startDate"
        title={translate('promotions.fields.startDate')}
        render={(_, promotion) => {
          return <DateField value={promotion.startDate} />
        }}
      />
      <Table.Column<Promotion>
        dataIndex="endDate"
        title={translate('promotions.fields.endDate')}
        render={(_, promotion) => {
          return <DateField value={promotion.endDate} />
        }}
      />
      <Table.Column<Promotion>
        dataIndex="price"
        title={translate('promotions.fields.price')}
        render={(_, promotion) => {
          return <CurrencyField value={promotion.price} />
        }}
      />

      <Table.Column<Promotion>
        dataIndex="actions"
        render={(_, promotion) => {
          const promotionId = cleanHydraId(promotion.id)
          return (
            <Space>
              <Link to={`/promotions/edit/${promotionId}`}>
                <EditButton onClick={undefined} />
              </Link>
              <DeleteButton
                resourceName={ResourcePathEnum.promotions}
                recordItemId={promotionId}
              />
            </Space>
          )
        }}
      />
    </Table>
  )
}
