import { InputNumber, NumberField } from '@pankod/refine'
import type { NumberFieldProps } from '@pankod/refine/dist/components/fields/number'

interface Props extends NumberFieldProps {
  value: number
}

export function CurrencyField(props: Props) {
  const { value, ...rest } = props

  const formatedValue = value / 100

  return (
    <NumberField
      value={formatedValue}
      options={{ style: 'currency', currency: 'EUR' }}
      {...rest}
    />
  )
}

interface CurrencyInputProps {
  value?: number
  onChange?: (value: number) => void
  required?: boolean
}

export function CurrencyInput(props: CurrencyInputProps) {
  const { value, onChange, required } = props

  const formatedValue = value && value / 100
  const handleChange = (newValue: number) => {
    return onChange?.(Math.round(newValue * 100))
  }

  return (
    <InputNumber
      value={formatedValue}
      onChange={handleChange}
      controls={false}
      decimalSeparator=","
      addonAfter="€"
      required={required}
    />
  )
}
