export function resizeImage(file: File) {
  return new Promise<File>((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = (e) => {
      if (e.target?.result) {
        const image = new Image()
        image.onload = () => {
          const canvas = document.createElement('canvas')
          const context = canvas.getContext('2d')

          const { height, width } = image

          if (height > width) {
            const resizedWidth = (width / height) * 1440
            canvas.height = 1440
            canvas.width = resizedWidth
            context?.drawImage(image, 0, 0, resizedWidth, 1440)
          } else {
            const resizedHeight = (height / width) * 1440
            canvas.width = 1440
            canvas.height = resizedHeight
            context?.drawImage(image, 0, 0, 1440, resizedHeight)
          }

          canvas.toBlob(
            (blob) => {
              if (blob) {
                return resolve(new File([blob], file.name))
              }
              reject(new Error('File unreadable'))
            },
            'image/jpeg',
            0.9,
          )
        }
        image.src = e.target?.result as string
      } else reject(new Error('File unreadable'))
    }

    reader.readAsDataURL(file)
  })
}
