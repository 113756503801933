import { useForm, Edit, Form, ListButton, RefreshButton } from '@pankod/refine'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { cleanHydraId } from 'src/adapters/DataProvider'
import type { Promotion } from 'src/types/api'

import { PromotionForm } from './PromotionForm'

export function EditView() {
  const history = useHistory()
  const { formProps, saveButtonProps, queryResult } = useForm<Promotion>({
    redirect: false,
    onMutationSuccess() {
      history.goBack()
    },
  })

  const itemId =
    queryResult?.data?.data.item && cleanHydraId(queryResult?.data?.data.item)

  return (
    <Edit
      saveButtonProps={saveButtonProps}
      deleteButtonProps={{
        onSuccess() {
          history.goBack()
        },
      }}
      pageHeaderProps={{
        extra: (
          <>
            {itemId ? (
              <Link to={`/items/edit/${itemId}`}>
                <ListButton onClick={undefined} />
              </Link>
            ) : (
              <ListButton onClick={undefined} loading />
            )}
            <RefreshButton />
          </>
        ),
      }}
    >
      <Form {...formProps} layout="vertical">
        <PromotionForm />
      </Form>
    </Edit>
  )
}
