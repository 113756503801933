import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
// import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import FirebaseUIAuth from 'react-firebaseui-localized'

import logoImg from '../UI/theme/logo.svg'
import './index.less'

export function LoginPage() {
  // Configure FirebaseUI.
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    signInOptions: [
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        disableSignUp: {
          status: true,
        },
      },
    ],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => false,
    },
  }

  return (
    <section className="AteAuthPage">
      <img src={logoImg} className="AteAuthPageLogo" />
      <FirebaseUIAuth
        lang={'fr'}
        config={uiConfig}
        version="6.0.0"
        auth={firebase.auth()}
        rtl={false}
        firebase={firebase}
      />
    </section>
  )
}
