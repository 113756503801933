import { Input } from '@pankod/refine'
import { CurrencyInput } from 'src/components/Currency'
import { DocumentUpload } from 'src/components/DocumentUpload'
import { FormItem } from 'src/components/FormItem'

interface Props {
  id: string
}

export function ItemForm(props: Props) {
  const { id } = props
  return (
    <>
      <FormItem label="items.fields.dispenser" name="dispenser" hidden />
      <FormItem label="items.fields.name" name="name">
        <Input maxLength={50} required />
      </FormItem>
      <FormItem label="items.fields.price" name="price">
        <CurrencyInput />
      </FormItem>

      <DocumentUpload
        label="items.fields.documents"
        resourceId={id}
        maxHeight={1440}
        maxWidth={1440}
      />

      <FormItem label="items.fields.description" name="description">
        <Input.TextArea rows={4} maxLength={2000} showCount />
      </FormItem>
    </>
  )
}
