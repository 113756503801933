import { useTranslate, useUpdate, Button, Icons } from '@pankod/refine'
import { useRefetch } from 'src/libs/useRefetch'
import type { ResourcePathEnum } from 'src/types/api'

interface Props<T> {
  resource: ResourcePathEnum | string
  record: T
  disableProp: keyof T
  isDisabled: boolean
}

interface Record {
  id: string
  [key: string]: any
}

export function ToggleEnabledButton<T extends Record>(props: Props<T>) {
  const { resource, record, disableProp, isDisabled } = props

  const translate = useTranslate()
  const { mutate, isLoading } = useUpdate()
  const { refetch, isFetching } = useRefetch()
  const pending = isLoading || isFetching

  const onClick = () => {
    mutate(
      {
        resource,
        id: record.id,
        values: {
          [disableProp]: !record[disableProp],
        },
      },
      {
        onSuccess() {
          refetch()
        },
      },
    )
  }

  return (
    <Button
      onClick={onClick}
      loading={pending}
      danger={!isDisabled}
      icon={isDisabled ? <Icons.CheckOutlined /> : <Icons.StopOutlined />}
    >
      {translate(isDisabled ? 'buttons.activate' : 'buttons.disable')}
    </Button>
  )
}
