import { Redirect } from 'react-router-dom'

export * from './AuthContext'
export * from './AuthProvider'

export const authRoutes = [
  {
    path: '/login',
    component() {
      return <Redirect to="/" />
    },
  },
  {
    path: '/signup',
    component() {
      return <Redirect to="/" />
    },
  },
]
