import type {
  DataProvider,
  GetListResponse,
  GetOneResponse,
} from '@pankod/refine'
import dayjs from 'dayjs'
import { hydrateDocuments } from 'src/components/DocumentUpload'
import type { PositionMap } from 'src/components/Map'
import type { ExtendedPosition } from 'src/Resources/DispenserPosition'

import { ResourcePathEnum } from 'src/types/api'
import type { Ad, Dispenser, Item } from 'src/types/api'

import type { ExtendedDispenser } from 'src/types/api/extendedTypes'

import type {
  CreateParams,
  GetListParams,
  HydraId,
  UpdateParams,
} from './DataProvider'
import { toHydraId } from './DataProvider'
import { DocumentHandlerProxy } from './documentsHandlerProxy'
import { manageSchedules } from './manageSchedules'

export function antiCorruptionLayerProxy(
  dataProvider: DataProvider,
  apiUrl: string,
) {
  const proxies = Proxies(dataProvider, apiUrl)
  return new Proxy(dataProvider, {
    get(_, methodName: keyof DataProvider) {
      if (typeof methodName !== 'string') return dataProvider[methodName]
      return function wrappedMethod(params: any) {
        if (!params) return (dataProvider[methodName] as any)(params)

        const { resource } = params
        if (resource in proxies) {
          // @ts-ignore
          const proxy = proxies[resource]
          if (proxy && methodName in proxy) {
            // @ts-ignore
            return proxy[methodName](params)
          }
        }

        return (dataProvider[methodName] as any)(params)
      }
    },
  })
}

type ProxiesMap = Partial<
  Record<ResourcePathEnum | string, Partial<DataProvider>>
>

function Proxies(dataProvider: DataProvider, apiUrl: string): ProxiesMap {
  return {
    ads: adsProxy(dataProvider),
    users: usersProxy(dataProvider),
    items: itemsProxy(dataProvider),
    dispensers: dispensersProxy(dataProvider),
    dispenserEdit: dispenserEditProxy(dataProvider, apiUrl),
    promotions: promotionsProxy(dataProvider),
    positions: positionsProxy(dataProvider),
    tags: tagsProxy(dataProvider),
    categories: categoriesProxy(dataProvider),
  }
}

function getAllListConstructor(
  dataProvider: DataProvider,
): (params: GetListParams) => Promise<GetListResponse<any>> {
  return async (params) => {
    const pageSize = 50
    let page = 1
    let hasAllData = false

    const result: GetListResponse = {
      data: [],
      total: 0,
    }

    while (hasAllData === false) {
      const request = await dataProvider.getList({
        ...params,
        pagination: { pageSize, current: page },
      })
      result.data = [...result.data, ...request.data]
      if (!result.total) {
        result.total = request.total
      }
      if (request.data.length < pageSize) {
        hasAllData = true
      }
      page++
    }

    return result
  }
}

function tagsProxy(dataProvider: DataProvider): DataProvider {
  return {
    ...dataProvider,
    getList: getAllListConstructor(dataProvider),
  }
}
function categoriesProxy(dataProvider: DataProvider): DataProvider {
  return {
    ...dataProvider,
    getList: getAllListConstructor(dataProvider),
  }
}

function adsProxy(dataProvider: DataProvider): DataProvider {
  return {
    ...dataProvider,
    ...DocumentHandlerProxy<Ad>({
      dataProvider,
      prop: 'document',
      single: true,
    }),
  }
}

function itemsProxy(dataProvider: DataProvider): DataProvider {
  return {
    ...dataProvider,
    ...DocumentHandlerProxy<Item>({ dataProvider, prop: 'documents' }),
  }
}

function dispensersProxy(dataProvider: DataProvider): DataProvider {
  const documentsHandlerProxy = DocumentHandlerProxy<Dispenser>({
    dataProvider,
    prop: 'documents',
  })
  function manageCategories(dispenser: Dispenser) {
    const { mainCategory, categories } = dispenser

    if (!mainCategory && !categories) return dispenser

    return {
      ...dispenser,
      categories: Array.from(new Set([mainCategory, ...(categories ?? [])])),
    }
  }

  return {
    ...dataProvider,
    async create(params) {
      const { variables } = params

      return documentsHandlerProxy.create({
        ...params,
        variables: manageCategories(variables as any as Dispenser),
      })
    },
    async update(params) {
      const { variables } = params

      return documentsHandlerProxy.update({
        ...params,
        variables: manageCategories(variables as any as Dispenser),
      })
    },
  }
}

function dispenserEditProxy(
  dataProvider: DataProvider,
  apiUrl: string,
): Partial<DataProvider> {
  return {
    async getOne(params): Promise<GetOneResponse<any>> {
      const result = await dataProvider.getOne({
        ...params,
        resource: ResourcePathEnum.dispensers,
      })
      const dispenser = result.data as ExtendedDispenser

      return {
        ...result,
        data: {
          ...dispenser,
          documents: hydrateDocuments(
            dispenser.documents.map((document) => document.id) ?? [],
            apiUrl,
          ),
        },
      }
    },
    async update(params) {
      return dispensersProxy(dataProvider).update({
        ...params,
        resource: ResourcePathEnum.dispensers,
      })
    },
  }
}

function usersProxy(dataProvider: DataProvider): Partial<DataProvider> {
  return {
    async update(params) {
      return dataProvider.update({
        ...params,
        variables: {
          ...params.variables,
          client: await handleNestedResource(
            dataProvider,
            { ...params, resource: ResourcePathEnum.clients },
            (params.variables as any).client,
          ),
        },
      })
    },
  }
}

async function handleNestedResource<T extends Record<string, any>>(
  dataProvider: DataProvider,
  params: UpdateParams | CreateParams,
  record: T | HydraId,
): Promise<HydraId | undefined> {
  if (!record || typeof record !== 'object') {
    return record as HydraId | undefined
  }

  const id = record['@id'] ?? record.id
  if (id) {
    await dataProvider.update({
      ...params,
      id,
      variables: record,
    })
    return id
  }

  // TODO use uuids when x-created-id works, for request parallelisation (and just return the created promise instead of awaiting) ?
  const result = await dataProvider.create({
    ...params,
    variables: record,
  })

  return result.data.id as HydraId
}

function promotionsProxy(dataProvider: DataProvider): Partial<DataProvider> {
  return {
    ...dataProvider,
    create(params) {
      const { variables } = params

      return dataProvider.create({
        ...params,
        variables: {
          ...variables,
          startDate: dayjs().toJSON(),
        },
      })
    },
  }
}

function positionsProxy(dataProvider: DataProvider): Partial<DataProvider> {
  function createPositions(dispenser: string, positions?: ExtendedPosition[]) {
    const positionsQueries = positions?.map(async (position) => {
      const result = await dataProvider.create({
        resource: ResourcePathEnum.dispenser_positions,
        variables: {
          ...position,
          dispenser,
          schedules: undefined,
        },
      })
      await manageSchedules(dataProvider, result.data.id!, position.schedules)
      return result
    })

    return Promise.allSettled(positionsQueries ?? [])
  }

  function updatePositions(dispenser: string, positions?: ExtendedPosition[]) {
    const positionsQueries = positions?.map(async (position) => {
      const schedules = await manageSchedules(
        dataProvider,
        toHydraId(ResourcePathEnum.dispenser_positions, position.id),
        position.schedules,
      )
      return dataProvider.update({
        resource: ResourcePathEnum.dispenser_positions,
        id: position.id,
        variables: {
          ...position,
          dispenser,
          schedules,
        },
      })
    })

    return Promise.allSettled(positionsQueries ?? [])
  }

  return {
    async update(params) {
      const { id, variables } = params
      const positions = Object.values(
        variables as any as PositionMap<ExtendedPosition>,
      )

      const positionsToCreate = positions.filter((position) => !position.exists)
      const positionsToEdit = positions.filter((position) => position.exists)

      const dispenser = toHydraId(ResourcePathEnum.dispensers, id)
      const createdPositions = await createPositions(
        dispenser,
        positionsToCreate,
      )
      updatePositions(dispenser, positionsToEdit)

      const createdPositionsIds = createdPositions
        .map((promiseResult) => {
          if (promiseResult.status === 'fulfilled') {
            return promiseResult.value.data.id
          }
          return undefined
        })
        .filter(Boolean)

      return dataProvider.update({
        resource: ResourcePathEnum.dispensers,
        id,
        variables: {
          positions: [
            ...createdPositionsIds,
            ...positionsToEdit.map((position) =>
              toHydraId(ResourcePathEnum.dispenser_positions, position.id),
            ),
          ],
        },
      })
    },
  }
}
