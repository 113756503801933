import type { IriReference, ResourcePathEnum } from 'src/types/api'

export function cleanHydraId(id: string) {
  if (isHydraId(id)) {
    return last(id.split('/'))
  }
  return id
}

export function toHydraId(
  resource: ResourcePathEnum,
  id: string,
): IriReference {
  if (typeof id !== 'string') return id
  if (isHydraId(id)) return id
  return `/api/${resource}/${id}`
}

function isHydraId(id?: string): id is IriReference {
  return Boolean(id && typeof id === 'string' && id.startsWith('/api/'))
}

function last<T>(array: T[]): T | undefined {
  return array[array.length - 1]
}
