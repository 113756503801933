import { Button, useTranslate, notification } from '@pankod/refine'
import firebase from 'firebase/compat/app'

import { useMutation } from 'react-query'
import 'firebase/compat/auth'

interface ResetPasswordButtonProps {
  email: string
}
export function ResetPasswordButton(props: ResetPasswordButtonProps) {
  const { email } = props
  const translate = useTranslate()

  const { mutate, isLoading } = useMutation({
    mutationFn() {
      return firebase.auth().sendPasswordResetEmail(email)
    },
    onSuccess() {
      notification.success({
        message: translate('notifications.resetPassword'),
      })
    },
  })
  return (
    <Button onClick={() => mutate()} loading={isLoading}>
      {translate('users.buttons.resetPassword')}
    </Button>
  )
}
