import StarEmpty from '@alltime-eat/design-tokens/graphics/react/IconStarEmpty'
import StarFilled from '@alltime-eat/design-tokens/graphics/react/IconStarFilled'

import type { ReactNode } from 'react'

interface RateProps {
  value: number
  starSize?: number
  text?: ReactNode
}
export function Rate(props: RateProps) {
  const { value, starSize = 24, text } = props

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {Array.from(Array(5)).map((_, index) => {
        if (index < value) {
          return <StarFilled height={starSize} width={starSize} key={index} />
        }
        return <StarEmpty height={starSize} width={starSize} key={index} />
      })}
      {text && <div style={{ marginLeft: '8px' }}>{text}</div>}
    </div>
  )
}
