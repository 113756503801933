import type { DataProvider as IDataProvider } from '@pankod/refine'
import { Button, Result, Refine } from '@pankod/refine'
import routerProvider from '@pankod/refine-react-router'
import { getAuth } from 'firebase/auth'
import { memo, Suspense, useContext, useMemo } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'

import { AccessControlProvider } from './AccessControlProvider'
import { antiCorruptionLayerProxy } from './adapters/antiCorruptionLayerProxy'
import { DataProvider } from './adapters/DataProvider'
import type { IEnv } from './adapters/Env'
import { useEnv, EnvContext } from './adapters/Env'
import { HTTPClient, HttpClientContext } from './adapters/HTTPClient'
import { useI18nProvider } from './adapters/i18nProvider'
import type { User as AuthUser } from './Auth'
import { authRoutes, AuthScope, AuthContext, AuthProvider } from './Auth'
import { deleteMeRoute } from './Auth/DeleteMePage'
import { Dashboard } from './Dashboard'
import { resources } from './Resources'
import {
  UpdatedDispensersContext,
  useWatchDispenserUpdates,
} from './Resources/Dispenser/Notify/useWatchDispenserUpdates'
import { DispenserRatingsRoute } from './Resources/Dispenser/Rating/DispenserRatings'
import { PositionRoute } from './Resources/DispenserPosition'
import { ResourcePathEnum } from './types/api'
import type { ExtendedUser } from './types/api/extendedTypes'
import { Menu, Title, LoadingPage, Layout } from './UI'
import { Footer } from './UI/Footer'
import './UI/index.less'

interface Props {
  env: IEnv
}

export function App(props: Props) {
  const { env } = props

  return (
    <Suspense fallback={<LoadingPage />}>
      <EnvContext.Provider value={env}>
        <ErrorBoundary FallbackComponent={ConnectionError}>
          <AuthScope>
            <AppContent />
          </AuthScope>
        </ErrorBoundary>
      </EnvContext.Provider>
    </Suspense>
  )
}

interface FallbackProps {
  error: Error
  resetErrorBoundary: (...args: Array<unknown>) => void
}

function ConnectionError(props: FallbackProps) {
  const { error, resetErrorBoundary } = props
  const auth = getAuth()

  const { t } = useTranslation()
  return (
    <Result
      status={403}
      title={t('pages.error.title')}
      subTitle={t(error.message)}
      extra={
        <Button
          onClick={() => {
            auth.signOut()
            resetErrorBoundary()
          }}
        >
          {t('buttons.logout')}
        </Button>
      }
    />
  )
}

const reactQueryClientConfig = {
  defaultOptions: {
    queries: {
      staleTime: 10 * 1000, // 10 seconds
    },
  },
}

const customRouterProvider = {
  ...routerProvider,
  routes: [...authRoutes, PositionRoute, DispenserRatingsRoute, deleteMeRoute],
}

const AppContent = memo(function AppContent() {
  const env = useEnv()
  const i18nProvider = useI18nProvider()
  const loggedUser = useContext(AuthContext) as AuthUser

  const httpClient = useMemo(
    () =>
      HTTPClient({
        getAccessToken() {
          return loggedUser.getApiToken()
        },
        baseURL: env.API_URL,
      }),
    [env.API_URL, loggedUser],
  )

  const dataProvider = useMemo(
    () =>
      antiCorruptionLayerProxy(
        DataProvider({
          httpClient: httpClient,
          apiUrl: env.API_URL,
        }),
        env.API_URL,
      ),
    [env.API_URL, httpClient],
  )

  const getMe = useGetMe(dataProvider, loggedUser)
  const authProvider = useMemo(
    () => AuthProvider({ loggedUser, getMe }),
    [loggedUser, getMe],
  )

  const accessControlProvider = useMemo(
    () =>
      AccessControlProvider({ getPermissions: authProvider.getPermissions }),
    [authProvider.getPermissions],
  )

  const [updatedDispensersStore, watchedDataProvider] =
    useWatchDispenserUpdates(dataProvider)

  return (
    <UpdatedDispensersContext.Provider value={updatedDispensersStore}>
      <HttpClientContext.Provider value={httpClient}>
        <Refine
          routerProvider={customRouterProvider}
          dataProvider={watchedDataProvider}
          i18nProvider={i18nProvider}
          authProvider={authProvider}
          accessControlProvider={accessControlProvider}
          reactQueryClientConfig={reactQueryClientConfig}
          resources={resources}
          Sider={Menu}
          Title={Title}
          Footer={Footer}
          DashboardPage={Dashboard}
          Layout={Layout}
        />
      </HttpClientContext.Provider>
    </UpdatedDispensersContext.Provider>
  )
})

function useGetMe(dataProvider: IDataProvider, loggedUser: AuthUser) {
  return useMemo(() => {
    async function getMe() {
      // TODO cache query ?
      const { data } = await dataProvider.getList<ExtendedUser>({
        resource: ResourcePathEnum.users,
        filters: [
          { field: 'firebaseId', operator: 'eq', value: loggedUser.uid },
        ],
      })

      if (!data.length) {
        throw Error(`No user with firebase id ${loggedUser.uid}`)
      }

      return data[0]
    }

    return cacheFunction(getMe, 1000 * 60)
  }, [dataProvider, loggedUser.uid])
}

function cacheFunction<T>(run: () => T, duration: number): () => T {
  let hasCache = false
  let cachedValue: T | null
  return () => {
    if (hasCache) {
      return cachedValue as T
    }

    cachedValue = run()
    hasCache = true
    setTimeout(() => {
      cachedValue = null
      hasCache = false
    }, duration)
    return cachedValue as T
  }
}
