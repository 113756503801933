import {
  Space,
  DeleteButton,
  SaveButton,
  useMutationMode,
  useNavigation,
} from '@pankod/refine'
import type { EditProps } from '@pankod/refine/dist/components/crud/edit'
import type { PropsWithChildren } from 'react'
import { useResourceFromProps } from 'src/libs/useResourceFromProps'

type EditActionsProps = Pick<
  EditProps,
  | 'saveButtonProps'
  | 'mutationMode'
  | 'deleteButtonProps'
  | 'canDelete'
  | 'resource'
>

// This comes from refine's sourceCode
export function DefaultEditActions(props: PropsWithChildren<EditActionsProps>) {
  const { saveButtonProps, children } = props
  const { deleteButtonProps, isDeleteButtonVisible } =
    useDeleteButtonProps(props)

  return (
    <Space>
      {isDeleteButtonVisible && (
        <DeleteButton data-testid="edit-delete-button" {...deleteButtonProps} />
      )}
      {children}
      <SaveButton {...saveButtonProps} />
    </Space>
  )
}

type UseDeleteButtonProps = Pick<
  EditProps,
  'mutationMode' | 'canDelete' | 'resource' | 'deleteButtonProps'
>

function useDeleteButtonProps(props: UseDeleteButtonProps) {
  const {
    canDelete,
    mutationMode: mutationModeProp,
    resource: resourceFromProps,
    deleteButtonProps,
  } = props
  const { resource } = useResourceFromProps({ resourceName: resourceFromProps })
  const { list } = useNavigation()
  const { mutationMode: mutationModeContext } = useMutationMode()
  const mutationMode = mutationModeProp ?? mutationModeContext

  const isDeleteButtonVisible =
    canDelete ?? (resource.canDelete || deleteButtonProps.canDelete)

  return {
    isDeleteButtonVisible,
    deleteButtonProps: {
      mutationMode,
      onSuccess() {
        list(resource.route ?? resource.name)
      },
      ...deleteButtonProps,
    },
  }
}
