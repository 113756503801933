// THIS FILE IS AUTO-GENERATED BY FIGMAGIC. DO NOT MAKE EDITS IN THIS FILE! CHANGES WILL GET OVER-WRITTEN BY ANY FURTHER PROCESSING.

const colors = {
 "onText": "rgba(255, 255, 255, 1)",
 "onError": "rgba(255, 255, 255, 1)",
 "onPrimary": "rgba(255, 255, 255, 1)",
 "onInfo": "rgba(255, 255, 255, 1)",
 "onSurface": "rgba(26, 47, 68, 1)",
 "onBackground": "rgba(26, 47, 68, 1)",
 "background": "rgba(249, 248, 249, 1)",
 "grey40": "rgba(243, 242, 242, 1)",
 "grey60": "rgba(236, 235, 236, 1)",
 "grey80": "rgba(230, 229, 229, 1)",
 "grey100": "rgba(224, 222, 223, 1)",
 "surface": "rgba(255, 255, 255, 1)",
 "error": "rgba(250, 0, 54, 1)",
 "info": "rgba(182, 17, 199, 1)",
 "primary": "rgba(252, 145, 50, 1)",
 "black80": "rgba(0, 0, 0, 0.8)",
 "black20": "rgba(0, 0, 0, 0.2)",
 "black40": "rgba(0, 0, 0, 0.4)",
 "black60": "rgba(49, 49, 50, 0.6)"
} as const;

export default colors;