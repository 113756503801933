import { Typography, useTranslate } from '@pankod/refine'
import { useEnv } from 'src/adapters/Env'
import { config } from 'src/config'

import './Footer.less'

const style = { fontSize: 12, margin: 4 }

export function Footer() {
  const __ = useTranslate()
  const env = useEnv()
  return (
    <div className="AteFooter">
      <Typography.Text style={style}>
        <a href={env.TERMS_URL}>{__('footer.terms')}</a>
        {' | '}
        <a href={env.CGU_URL}>{__('footer.CGU')}</a>
        {' | '}
        <a href={env.CGV_URL}>{__('footer.CGV')}</a>
      </Typography.Text>
      <Typography.Text style={style}>
        {config.APP_DISPLAY_NAME} (v{config.APP_VERSION}) — API (v
        {config.API_VERSION})
      </Typography.Text>
    </div>
  )
}
