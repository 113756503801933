import axios from 'axios'
import type { HydraId } from 'src/adapters/DataProvider'
import { cleanHydraId } from 'src/adapters/DataProvider'
import { useEnv } from 'src/adapters/Env'
import { useHttpClient } from 'src/adapters/HTTPClient'

export function useMinioFileUpload() {
  const httpClient = useHttpClient()

  const upload = async (
    fileName: string,
    file: File,
    bucketId: string,
    onProgress?: (event: any) => void,
  ) => {
    const urlResponse = await httpClient.get(
      `minio/presigned_url/${fileName}/${cleanHydraId(bucketId)}`,
    )
    return axios.put(urlResponse.data, file, {
      headers: { 'Content-Type': file.type },
      onUploadProgress: onProgress,
    })
  }

  return upload
}

export function useDocumentUrl(documentId?: HydraId) {
  const { API_URL } = useEnv()

  if (!documentId) return
  return `${API_URL}/minio/document/${cleanHydraId(documentId)}`
}
