import { ResourcePathEnum } from '../../types/api'

import { CreateView } from './Create'
import { EditView } from './Edit'
import { ListView } from './List'

export const ad = {
  name: ResourcePathEnum.ads,
  list: ListView,
  edit: EditView,
  create: CreateView,
  canDelete: true,
}
