import { useForm, Form, Input, Create, useTranslate } from '@pankod/refine'
import type { User } from 'src/types/api'

export function CreateView() {
  const { formProps, saveButtonProps } = useForm<User>({
    redirect: 'list',
  })
  const translate = useTranslate()

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label={translate('users.fields.email')}
          name="email"
          required
          rules={[
            {
              type: 'email',
              message: translate('validation.invalidEmail'),
            },
          ]}
        >
          <Input type="email" />
        </Form.Item>
        <Form.Item label={translate('users.fields.code')} name="code">
          <Input maxLength={50} />
        </Form.Item>
      </Form>
    </Create>
  )
}
