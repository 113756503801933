import {
  EditButton as RefineEditButton,
  ShowButton as RefineShowButton,
} from '@pankod/refine'
import { cleanHydraId } from 'src/adapters/DataProvider'

export function EditButton(props: Parameters<typeof RefineEditButton>[0]) {
  return (
    <RefineEditButton
      {...props}
      recordItemId={props.recordItemId && cleanHydraId(props.recordItemId)}
    />
  )
}

export function ShowButton(props: Parameters<typeof RefineShowButton>[0]) {
  return (
    <RefineShowButton
      {...props}
      recordItemId={props.recordItemId && cleanHydraId(props.recordItemId)}
    />
  )
}
