import { Space, Typography, useTranslate } from '@pankod/refine'
import type { ReactElement } from 'react'
import './index.less'

const { Title } = Typography

interface Props {
  title: string
  actions?: ReactElement | ReactElement[] | false
}

export function TitleWithActions(props: Props) {
  const { title, actions } = props
  const translate = useTranslate()

  return (
    <div className="AteTitleWithActions">
      <Title level={5}>{translate(title)}</Title>
      <Space>{actions}</Space>
    </div>
  )
}
