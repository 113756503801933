// THIS FILE IS AUTO-GENERATED BY FIGMAGIC. DO NOT MAKE EDITS IN THIS FILE! CHANGES WILL GET OVER-WRITTEN BY ANY FURTHER PROCESSING.

const lineHeights = {
 "label": "1.33",
 "button": "1.71",
 "microbutton": "1.20",
 "microcopyBold": "1.60",
 "microcopy": "1.20",
 "bodySBold": "1.33",
 "bodyS": "1.33",
 "bodyM": "1.60",
 "bodyL": "1.60",
 "headingXs": "1.14",
 "headingS": "1.20",
 "headingM": "1.00",
 "headingL": "1.11"
} as const;

export default lineHeights;