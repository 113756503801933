import { ResourcePathEnum } from '../../types/api'

import { CreateView } from './Create'

import { EditView } from './Edit'
import { ListView } from './List'
import { MobileUserListView } from './MobileUserList'
import { ShowView } from './Show'

export const user = {
  name: ResourcePathEnum.users,
  list: ListView,
  edit: EditView,
  show: ShowView,
  create: CreateView,
}

export const mobileUserResource = 'mobile-users'

export const mobileUser = {
  name: mobileUserResource,
  list: MobileUserListView,
}
