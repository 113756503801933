import type { ResourceRouterParams } from '@pankod/refine'
import {
  Typography,
  Space,
  useNavigation,
  Skeleton,
  useUpdate,
  Edit,
  useTranslate,
  Spin,
  useShow,
  Authenticated,
  LayoutWrapper,
} from '@pankod/refine'
import type { ProfunctorState } from '@staltz/use-profunctor-state'
import useProfunctorState from '@staltz/use-profunctor-state'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import type { Position, PositionMap } from 'src/components/Map'
import { Map } from 'src/components/Map'
import type { Schedule } from 'src/components/ScheduleForm'
import { ResourcePathEnum } from 'src/types/api'
import type { ExtendedDispenser } from 'src/types/api/extendedTypes'

import { MultiplePosition } from './MultiplePosition'
import { PositionCard } from './PositionCard'

import './style.less'

export const PositionRoute = {
  path: '/dispensers/:id/positions',
  component() {
    return (
      <Authenticated>
        <LayoutWrapper>
          <PositionPage />
        </LayoutWrapper>
      </Authenticated>
    )
  },
}

export interface ExtendedPosition extends Position {
  schedules?: Schedule[]
}

function PositionPage() {
  const { id } = useParams<ResourceRouterParams>()
  const translate = useTranslate()
  const history = useHistory()

  const { queryResult } = useShow<ExtendedDispenser>({
    resource: ResourcePathEnum.dispensers,
    id,
  })
  const { data, isLoading } = queryResult
  const dispenser = data?.data

  const hasMultiplePosition = dispenser?.isFoodtruck

  const positionsStore = useProfunctorState<PositionMap<ExtendedPosition>>({})
  const { state: positions, setState: setPositions } = positionsStore
  const [selectedPosition, setSelectedPosition] = useState<keyof PositionMap>()

  useEffect(() => {
    if (dispenser?.positions) {
      const positionsMap: PositionMap<ExtendedPosition> = {}
      dispenser.positions.forEach((position) => {
        positionsMap[position.id] = {
          ...position,
          schedules: position.schedules.map((schedule) => {
            return { ...schedule, exists: true }
          }),
          exists: true,
        }
      })
      setPositions(() => positionsMap)
      setSelectedPosition(dispenser.positions[0]?.id)
    }
  }, [dispenser?.positions, setPositions])

  const { mutate, isLoading: updateLoading } = useUpdate()
  const { show } = useNavigation()

  const save = () => {
    mutate(
      {
        resource: 'positions',
        id: id,
        values: positions,
      },
      {
        onSuccess() {
          show(ResourcePathEnum.dispensers, id)
        },
      },
    )
  }

  const props = {
    positionsStore,
    selectedPosition,
    setSelectedPosition,
  }

  const positionLoading = Object.values(positions).some((pos) => pos.isLoading)

  return (
    <Edit
      title={translate('dispenserPositions.title')}
      pageHeaderProps={{
        subTitle: dispenser?.name,
        extra: undefined,
        onBack: history.goBack,
      }}
      saveButtonProps={{
        loading: isLoading || updateLoading || positionLoading,
        onClick: save,
        size: 'large',
      }}
    >
      <Spin spinning={isLoading}>
        <Space direction="vertical" style={{ width: '100%' }}>
          {hasMultiplePosition === undefined ? (
            <Skeleton active />
          ) : (
            <div className="AteMapLayout">
              <Body hasMultiplePosition={hasMultiplePosition} {...props} />
            </div>
          )}
          <Space>
            <Typography.Paragraph>
              {translate('components.map.info.createAndMoveMarker')}
            </Typography.Paragraph>
          </Space>
        </Space>
      </Spin>
    </Edit>
  )
}

interface Props {
  hasMultiplePosition: boolean
  positionsStore: ProfunctorState<PositionMap<ExtendedPosition>>
  selectedPosition: keyof PositionMap | undefined
  setSelectedPosition: Dispatch<SetStateAction<keyof PositionMap | undefined>>
}
function Body(props: Props) {
  const { hasMultiplePosition, selectedPosition, positionsStore } = props

  const { state: positions } = positionsStore

  if (hasMultiplePosition) {
    return <MultiplePosition {...props} />
  }
  return (
    <>
      <div className="AteUniquePositionCard">
        {selectedPosition && (
          <PositionCard
            positionsStore={positionsStore}
            position={positions[selectedPosition]}
          />
        )}
      </div>
      <Map {...props} />
    </>
  )
}
