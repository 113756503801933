import type { ButtonProps, FormProps } from '@pankod/refine'
import { useForm, Create, Form, useOne, useGetIdentity } from '@pankod/refine'
import { useHistory } from 'react-router'
import { cleanHydraId, toHydraId } from 'src/adapters/DataProvider'
import type { UserIdentity } from 'src/Auth'
import { FormItem } from 'src/components/FormItem'
import { useParsedLocation } from 'src/libs/useParsedLocation'
import type { Client, Dispenser } from 'src/types/api'
import { ResourcePathEnum } from 'src/types/api'
import { useMemoOne } from 'use-memo-one'
import { v4 as uuidv4 } from 'uuid'

import { DispenserForm } from './DispenserForm'

export function CreateView() {
  const history = useHistory()
  const { formProps, saveButtonProps } = useForm<Dispenser>({
    redirect: false,
    onMutationSuccess(result) {
      const id = cleanHydraId(result.data.id)
      history.replace(`/dispensers/show/${id}`)
    },
  })

  const { data, isLoading } = useGetIdentity<UserIdentity>()

  const uuid = useMemoOne(() => {
    return uuidv4()
  }, [])

  if (isLoading) {
    return (
      <Create saveButtonProps={saveButtonProps} isLoading>
        <Form {...formProps} layout="vertical">
          <FormItem name="id" hidden />
          <FormItem name="owner" hidden />
          <DispenserForm id={uuid} isCreate />
        </Form>
      </Create>
    )
  }

  if (data && data.me.isAdmin) {
    return (
      <AdminCreate
        uuid={uuid}
        saveButtonProps={saveButtonProps}
        formProps={formProps}
      />
    )
  }

  if (data) {
    if (!data.me.client) {
      throw new Error('users.noClient')
    }
    const { client } = data.me

    if (client.dispenserLimit <= client.dispensers.length) {
      throw new Error('pages.error.dispenserLimitReached')
    }
  }

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          id: uuid,
          owner: toHydraId(ResourcePathEnum.clients, data?.me.client?.id ?? ''),
        }}
      >
        <FormItem name="id" hidden />
        <FormItem name="owner" hidden />
        <DispenserForm id={uuid} isCreate />
      </Form>
    </Create>
  )
}

interface Props {
  uuid: string
  saveButtonProps: ButtonProps & {
    onClick: () => void
  }
  formProps: FormProps
}
function AdminCreate(props: Props) {
  const { uuid, saveButtonProps, formProps } = props
  const { query } = useParsedLocation<{ client: string }>()
  const { client } = query

  if (!client) throw new Error('pages.error.clientMissing')

  const { data: clientQuery, isLoading } = useOne<Client>({
    resource: ResourcePathEnum.clients,
    id: client ?? '',
    queryOptions: {
      useErrorBoundary: true,
      enabled: Boolean(client),
    },
  })

  if (
    clientQuery?.data &&
    clientQuery.data.dispenserLimit <= clientQuery.data.dispensers.length
  ) {
    throw new Error('pages.error.dispenserLimitReached')
  }

  return (
    <Create saveButtonProps={saveButtonProps} isLoading={isLoading}>
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          id: uuid,
          owner: toHydraId(ResourcePathEnum.clients, client),
        }}
      >
        <FormItem name="id" hidden />
        <FormItem name="owner" hidden />
        <DispenserForm id={uuid} isCreate />
      </Form>
    </Create>
  )
}
