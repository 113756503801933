import { useOne, TagField, Skeleton, Space } from '@pankod/refine'
import { Suspense } from 'react'
import type { HydraId } from 'src/adapters/DataProvider'
import type { Category } from 'src/types/api'
import { ResourcePathEnum } from 'src/types/api'

interface CategoriesFieldProps {
  value?: HydraId[]
  mainCategory?: HydraId
}

export function CategoriesField(props: CategoriesFieldProps) {
  const { value, mainCategory } = props
  const fallback = <Skeleton.Button size="small" />

  if (!value) return fallback

  return (
    <Suspense fallback={fallback}>
      <Space>
        {mainCategory && <CategoryField value={mainCategory} isMain />}

        {value
          .filter((id) => id !== mainCategory)
          .map((id) => (
            <CategoryField key={id} value={id} />
          ))}
      </Space>
    </Suspense>
  )
}

interface CategoryFieldProps {
  value: HydraId
  isMain?: boolean
}

function CategoryField(props: CategoryFieldProps) {
  const { value, isMain } = props

  const { data: category } = useOne<Category>({
    resource: ResourcePathEnum.categories,
    id: value,
    queryOptions: {
      suspense: true,
    },
  })

  return (
    <TagField
      value={category?.data.name}
      color={isMain ? 'success' : 'default'}
    />
  )
}
