import queryString from 'query-string'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export function useParsedLocation<Query extends Record<string, any>>() {
  const location = useLocation()
  const query = useMemo(
    () =>
      queryString.parse(location.search) as {
        [K in keyof Query]: Query[K] | undefined
      },
    [location.search],
  )

  return {
    ...location,
    query,
  }
}
