// THIS FILE IS AUTO-GENERATED BY FIGMAGIC. DO NOT MAKE EDITS IN THIS FILE! CHANGES WILL GET OVER-WRITTEN BY ANY FURTHER PROCESSING.

const letterSpacings = {
 "label": "0em",
 "button": "0em",
 "microbutton": "0em",
 "microcopyBold": "0.03em",
 "microcopy": "0em",
 "bodySBold": "0em",
 "bodyS": "0em",
 "bodyM": "0em",
 "bodyL": "0em",
 "headingXs": "0em",
 "headingS": "0em",
 "headingM": "0em",
 "headingL": "0em"
} as const;

export default letterSpacings;