import type { InputNumberProps } from '@pankod/refine'
import { InputNumber, Icons } from '@pankod/refine'

export function NumberInput(
  props: Omit<InputNumberProps, 'addonBefore' | 'addonAfter'>,
) {
  const { onChange, step = 1, value, min, max } = props

  const onAdd = () => {
    const newValue = Number(value) + Number(step)
    if (max && newValue > Number(max)) return onChange?.(max)

    onChange?.(newValue)
  }

  const onMinus = () => {
    const newValue = Number(value) - Number(step)
    if (min && newValue < Number(min)) return onChange?.(min)

    onChange?.(newValue)
  }
  return (
    <InputNumber
      {...props}
      controls={false}
      addonBefore={<Icons.MinusOutlined onClick={onMinus} />}
      addonAfter={<Icons.PlusOutlined onClick={onAdd} />}
    />
  )
}
