import { useForm, Create, Form } from '@pankod/refine'
import { FormItem } from 'src/components/FormItem'
import type { Ad } from 'src/types/api'
import { useMemoOne } from 'use-memo-one'
import { v4 as uuid } from 'uuid'

import { AdForm } from './AdForm'

export function CreateView() {
  const { formProps, saveButtonProps } = useForm<Ad>()

  const id = useMemoOne(() => {
    return uuid()
  }, [])

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          id,
        }}
      >
        <FormItem label="id" name="id" hidden />
        <AdForm id={id} />
      </Form>
    </Create>
  )
}
