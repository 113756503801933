import { useOne } from '@pankod/refine'

import type { RecordProps } from './useResourceFromProps'
import { useResourceFromProps } from './useResourceFromProps'

export function useRefetch(props?: RecordProps) {
  // inspired by refine's RefetchButton
  const { resource, recordItemId } = useResourceFromProps(props)

  const { refetch, isFetching } = useOne({
    resource: resource.name,
    id: recordItemId,
    queryOptions: {
      enabled: false,
    },
    liveMode: 'off',
  })

  return { refetch, isFetching }
}
