import { List, Space, Table, useTable, useTranslate } from '@pankod/refine'
import { DateField } from 'src/components/DateField'
import { EditButton } from 'src/components/ResourceButtons'
import type { Ad } from 'src/types/api'
import { ResourcePathEnum } from 'src/types/api'

export function ListView() {
  const translate = useTranslate()
  const { tableProps } = useTable<Ad>({
    resource: ResourcePathEnum.ads,
  })
  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="label" title={translate('ads.fields.label')} />
        <Table.Column
          dataIndex="createdAt"
          title={translate('ads.fields.createdAt')}
          render={(value) => <DateField value={value} />}
        />
        <Table.Column<Ad>
          dataIndex="actions"
          render={(_, ad) => {
            return (
              <Space style={{ justifyContent: 'flex-end', width: '100%' }}>
                <EditButton recordItemId={ad?.id} />
              </Space>
            )
          }}
        />
      </Table>
    </List>
  )
}
