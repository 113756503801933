import {
  fontFamilies,
  fontSizes,
  fontWeights,
  letterSpacings,
  lineHeights,
} from '@alltime-eat/design-tokens'
import type { CSSProperties } from 'react'

export function getTypographyStyle(
  name: keyof typeof fontFamilies,
): CSSProperties {
  return {
    fontFamily: fontFamilies[name],
    fontSize: fontSizes[name],
    fontWeight: fontWeights[name],
    letterSpacing: letterSpacings[name],
    lineHeight: lineHeights[name],
  }
}
