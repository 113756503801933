import type { ResourceRouterParams } from '@pankod/refine'
import {
  useOne,
  Show,
  Typography,
  Tag,
  useTranslate,
  Icons,
  usePermissions,
} from '@pankod/refine'
import { Button, Divider } from 'antd'
import { Link, useParams } from 'react-router-dom'
import { DocumentsField } from 'src/components/DocumentsField'
import { EmptyText } from 'src/components/EmptyText'
import { TextEditor } from 'src/components/TextEditor'
import { TextField } from 'src/components/TextField'
import { TitleWithActions } from 'src/components/TitleWithActions'
import { ResourcePathEnum } from 'src/types/api'
import type { ExtendedDispenser } from 'src/types/api/extendedTypes'
import { RoleEnum } from 'src/types/api/extendedTypes'

import { CategoriesField } from './CategoryField'
import { DispenserLogs } from './DispenserLogs'
import { DispenserTagsField } from './DispenserTagField'
import { SuggestNotify } from './Notify/SuggestNotify'
import { ProductsField } from './ProductField'
import { RatingCard } from './Rating/RatingCard'

const { Title, Paragraph } = Typography

export function ShowView() {
  const translate = useTranslate()
  const { data: permissions } = usePermissions<RoleEnum[]>()
  const { id: dispenserId } = useParams<ResourceRouterParams>()

  const { data, isLoading } = useOne<ExtendedDispenser>({
    resource: ResourcePathEnum.dispensers,
    id: dispenserId,
  })

  const dispenser = data?.data

  return (
    <>
      <SuggestNotify dispenserId={data?.data['@id']} />
      <br />
      <Show
        isLoading={isLoading}
        canDelete={false}
        pageHeaderProps={{
          subTitle: (
            <Tag>
              {translate(
                dispenser?.isDisabledByClient
                  ? 'dispensers.values.isDisabled.byClient'
                  : dispenser?.isDisabled
                  ? 'dispensers.values.isDisabled.true'
                  : 'dispensers.values.isDisabled.false',
              )}
            </Tag>
          ),
        }}
      >
        {dispenser && (
          <Link to={`/dispensers/${dispenserId}/avis`}>
            <RatingCard
              count={dispenser.nbRatings}
              value={dispenser.averageRating}
            />
          </Link>
        )}
        <TextField
          record={dispenser}
          name="name"
          label="dispensers.fields.name"
        />

        <TextField
          record={dispenser}
          name="code"
          label="dispensers.fields.code"
        />

        <Title level={5}>{translate('dispensers.fields.documents')}</Title>
        <Paragraph>
          <DocumentsField documents={dispenser?.documents} />
        </Paragraph>

        <Title level={5}>{translate('dispensers.fields.positions')}</Title>
        <Paragraph>
          <Link to={`/dispensers/${dispenserId}/positions`}>
            <Button>{translate('dispensers.actions.managePositions')}</Button>
          </Link>
        </Paragraph>

        <Title level={5}>{translate('dispensers.fields.description')}</Title>
        {dispenser?.description ? (
          <TextEditor value={dispenser?.description} readonly />
        ) : (
          <EmptyText />
        )}

        <Title level={5}>{translate('dispensers.fields.tags')}</Title>
        <Paragraph>
          <DispenserTagsField value={dispenser?.tags} size="large" />
        </Paragraph>

        <Title level={5}>{translate('dispensers.fields.categories')}</Title>
        <Paragraph>
          <CategoriesField
            value={dispenser?.categories}
            mainCategory={dispenser?.mainCategory}
          />
        </Paragraph>

        <TextField
          record={dispenser}
          name="websiteUrl"
          label="dispensers.fields.websiteUrl"
          link
        />
        <TextField
          record={dispenser}
          name="facebookUrl"
          label="dispensers.fields.facebookUrl"
          link
        />
        <TextField
          record={dispenser}
          name="phoneNumber"
          label="dispensers.fields.phoneNumber"
        />
        <TitleWithActions
          title="dispensers.fields.items"
          actions={
            dispenser && (
              <Link to={`/items/create?dispenser=${dispenserId}`}>
                <Button icon={<Icons.PlusOutlined />}>
                  {translate('buttons.create')}
                </Button>
              </Link>
            )
          }
        />
        <ProductsField dispenser={dispenser} />

        {permissions?.includes(RoleEnum.admin) && (
          <>
            <Divider />
            <DispenserLogs id={dispenserId} />
          </>
        )}
      </Show>
    </>
  )
}
