import type { LayoutProps } from '@pankod/refine'
import { Skeleton, AntdLayout } from '@pankod/refine'
import type { PropsWithChildren } from 'react'
import { Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import { AcceptLegalTextModal } from 'src/components/AcceptLegalTextModal'

import { ErrorPage } from './ErrorPage'

export function Layout(props: PropsWithChildren<LayoutProps>) {
  const { children, Sider, Footer, Header, OffLayoutArea } = props
  return (
    <AntdLayout style={{ height: '100vh', flexDirection: 'row' }}>
      <Sider />
      <AntdLayout>
        <Header />
        <AntdLayout.Content style={{ overflowY: 'scroll' }}>
          <div style={{ padding: 24, minHeight: 360 }}>
            <Suspense fallback={<Skeleton />}>
              <ErrorBoundary FallbackComponent={ErrorPage}>
                {children}
              </ErrorBoundary>
            </Suspense>
          </div>
        </AntdLayout.Content>
        <Footer />
      </AntdLayout>
      <OffLayoutArea />
      <AcceptLegalTextModal />
    </AntdLayout>
  )
}
