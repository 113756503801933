// THIS FILE IS AUTO-GENERATED BY FIGMAGIC. DO NOT MAKE EDITS IN THIS FILE! CHANGES WILL GET OVER-WRITTEN BY ANY FURTHER PROCESSING.

const fontWeights = {
 "label": 400,
 "button": 700,
 "microbutton": 700,
 "microcopyBold": 700,
 "microcopy": 400,
 "bodySBold": 700,
 "bodyS": 400,
 "bodyM": 400,
 "bodyL": 400,
 "headingXs": 700,
 "headingS": 700,
 "headingM": 700,
 "headingL": 700
} as const;

export default fontWeights;