import type { FormItemProps } from '@pankod/refine'
import { Form, useTranslate } from '@pankod/refine'

type Props<T> = Omit<FormItemProps<T>, 'label'> & {
  label?: string
}
export function FormItem<T>(props: Props<T>) {
  const { label } = props
  const translate = useTranslate()
  return (
    <Form.Item
      {...props}
      rules={[
        {
          required: props.required,
          message: translate('validation.required'),
        },
        ...(props.rules ?? []),
      ]}
      label={label && translate(label)}
    />
  )
}
