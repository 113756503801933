import { Tooltip, useOne, Image, Space, Skeleton } from '@pankod/refine'
import { Suspense } from 'react'
import type { HydraId } from 'src/adapters/DataProvider'
import { useDocumentUrl } from 'src/libs/useMinioFile'
import type { Tag } from 'src/types/api'
import { ResourcePathEnum } from 'src/types/api'

interface DispenserTagsFieldProps {
  value?: HydraId[]
  size?: 'small' | 'large' | 'default'
}

export function DispenserTagsField(props: DispenserTagsFieldProps) {
  const { value, size = 'default' } = props
  const fallback = <Skeleton.Avatar size={size} />

  if (!value) return fallback
  return (
    <Suspense fallback={fallback}>
      <Space>
        {value.map((tag) => (
          <DispenserTagField key={tag} value={tag} size={size} />
        ))}
      </Space>
    </Suspense>
  )
}

interface DispenserTagFieldProps {
  value: HydraId
  size: 'small' | 'large' | 'default'
}
function DispenserTagField(props: DispenserTagFieldProps) {
  const { value, size } = props

  const { data: tag } = useOne<Tag>({
    resource: ResourcePathEnum.tags,
    id: value,
    queryOptions: {
      suspense: true,
    },
  })

  const file = useDocumentUrl(tag?.data.document)
  const displaySize = {
    small: 16,
    default: 32,
    large: 64,
  }[size]

  return (
    <Tooltip title={tag?.data.name}>
      <Image
        src={file}
        width={displaySize}
        height={displaySize}
        preview={false}
        placeholder={
          <Skeleton.Image style={{ width: displaySize, height: displaySize }} />
        }
      />
    </Tooltip>
  )
}
