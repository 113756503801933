import { ResourcePathEnum } from '../../types/api'

import { CreateView } from './Create'
import { EditView } from './Edit'

export const promotion = {
  name: ResourcePathEnum.promotions,
  options: { label: 'Promotions' },
  edit: EditView,
  create: CreateView,
  canDelete: true,
}
