import type { ResourceRouterParams } from '@pankod/refine'
import { useForm, Edit, Form } from '@pankod/refine'
import { useEffect } from 'react'
import { useParams } from 'react-router'
import { useEnv } from 'src/adapters/Env'
import { hydrateDocuments } from 'src/components/DocumentUpload'
import type { Ad } from 'src/types/api'

import { AdForm } from './AdForm'

export function EditView() {
  const env = useEnv()
  const { id } = useParams<ResourceRouterParams>()
  const { formProps, saveButtonProps, queryResult, form } = useForm<Ad>()

  useEffect(() => {
    if (queryResult?.isFetched) {
      const ad = queryResult?.data?.data

      form.setFieldsValue({
        ...ad,
        document: hydrateDocuments(
          ad?.document ? [ad.document] : [],
          env.API_URL,
        ),
      })
    }
  }, [queryResult?.data?.data, form, env.API_URL, queryResult?.isFetched])

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <AdForm id={id} />
      </Form>
    </Edit>
  )
}
