import { Input, useTranslate } from '@pankod/refine'
import Fraction from 'fraction.js'
import { DocumentUpload } from 'src/components/DocumentUpload'
import { FormItem } from 'src/components/FormItem'

interface Props {
  id: string
}
export function AdForm(props: Props) {
  const { id } = props
  const translate = useTranslate()
  return (
    <>
      <FormItem
        label="ads.fields.label"
        name="label"
        rules={[
          {
            required: true,
            message: translate('validation.required'),
          },
        ]}
      >
        <Input />
      </FormItem>
      <FormItem
        label="ads.fields.url"
        name="url"
        rules={[
          {
            required: true,
            message: translate('validation.required'),
          },
          {
            type: 'url',
            message: translate('validation.invalidUrl'),
          },
        ]}
        required
      >
        <Input />
      </FormItem>

      <DocumentUpload
        label="ads.fields.document"
        resourceId={id}
        maxWidth={1440}
        ratio={new Fraction(43, 12)}
        single
        documentInfo="ads.imageRestriction"
        required
      />
    </>
  )
}
