// THIS FILE IS AUTO-GENERATED BY FIGMAGIC. DO NOT MAKE EDITS IN THIS FILE! CHANGES WILL GET OVER-WRITTEN BY ANY FURTHER PROCESSING.

const fontSizes = {
 "label": "0.9375rem",
 "button": "0.875rem",
 "microbutton": "0.625rem",
 "microcopyBold": "0.625rem",
 "microcopy": "0.625rem",
 "bodySBold": "0.75rem",
 "bodyS": "0.75rem",
 "bodyM": "0.9375rem",
 "bodyL": "1.25rem",
 "headingXs": "0.875rem",
 "headingS": "1.25rem",
 "headingM": "1.5rem",
 "headingL": "2.25rem"
} as const;

export default fontSizes;