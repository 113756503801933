import type { ProfunctorState } from '@staltz/use-profunctor-state'
import type { Dispatch, SetStateAction } from 'react'
import type { PositionMap } from 'src/components/Map'
import { Map } from 'src/components/Map'

import { PositionCard } from './PositionCard'

import type { ExtendedPosition } from '.'

interface Props {
  positionsStore: ProfunctorState<PositionMap<ExtendedPosition>>
  selectedPosition: keyof PositionMap | undefined
  setSelectedPosition: Dispatch<SetStateAction<keyof PositionMap | undefined>>
}

export function MultiplePosition(props: Props) {
  const { positionsStore, selectedPosition, setSelectedPosition } = props
  const { state: positions, setState } = positionsStore

  const deletePosition = (id: string) => {
    setState((prevPositions) => {
      const { [id]: _deletedPosition, ...newPositions } = prevPositions
      if (selectedPosition === id) setSelectedPosition(undefined)
      return newPositions
    })
  }

  return (
    <div className="AteMultiplePositionLayout">
      <div className="AtePositionsList">
        {Object.values(positions).map((position) => (
          <div
            key={position.id}
            onClick={() => setSelectedPosition(position.id)}
            className="AteClickable"
          >
            <PositionCard
              positionsStore={positionsStore}
              position={position}
              active={selectedPosition === position.id}
              deletePosition={deletePosition}
            />
          </div>
        ))}
      </div>
      <div className="AteMapContainer">
        <Map {...props} maxMarker={14} />
      </div>
    </div>
  )
}
