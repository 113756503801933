import { Skeleton, Space, Image, useTranslate } from '@pankod/refine'
import { useDocumentUrl } from 'src/libs/useMinioFile'
import type { Document } from 'src/types/api'

interface Props {
  documents?: Document[]
}

export function DocumentsField(props: Props) {
  const { documents } = props
  const translate = useTranslate()

  if (!documents || documents.length === 0) {
    return <>{translate('components.documentsField.empty')}</>
  }
  return (
    <Space>
      {documents?.map((document) => (
        <DocumentField key={document.id} document={document} />
      ))}
    </Space>
  )
}
interface DocumentFieldProps {
  document: Document
}
const IMAGE_SIZE = 128
function DocumentField(props: DocumentFieldProps) {
  const { document } = props

  const file = useDocumentUrl(document.id)
  return (
    <Image
      src={file}
      height={IMAGE_SIZE}
      placeholder={
        <Skeleton.Image style={{ width: IMAGE_SIZE, height: IMAGE_SIZE }} />
      }
    />
  )
}
