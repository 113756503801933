import { colors } from '@alltime-eat/design-tokens'
import { Typography, Card, useTranslate } from '@pankod/refine'
import { Rate } from 'src/components/Rate'
import { getTypographyStyle } from 'src/utils/getTypographyStyle'

interface RatingCardProps {
  value: number
  count: number
}
export function RatingCard(props: RatingCardProps) {
  const { value, count } = props
  const translate = useTranslate()
  return (
    <Card
      style={{
        width: 'fit-content',
        background: colors.background,
        marginBottom: '8px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
        }}
      >
        <Typography
          style={{
            ...getTypographyStyle('headingS'),
            color: colors.black80,
            textAlign: 'center',
          }}
        >
          {translate('dispensers.fields.rateCount', {
            count: count,
          })}
        </Typography>
        <Rate
          value={value}
          starSize={32}
          text={
            <Typography.Text
              style={{
                ...getTypographyStyle('headingM'),
                color: colors.black40,
              }}
            >{`${value}/5`}</Typography.Text>
          }
        />
      </div>
    </Card>
  )
}
