import { ResourcePathEnum } from '../../types/api'

import { CreateView } from './Create'
import { EditView } from './Edit'
import { ListView } from './List'
import { ShowView } from './Show'

export const config = {
  name: ResourcePathEnum.configs,
  options: { label: 'Configuration' },
  list: ListView,
  show: ShowView,
  edit: EditView,
  create: CreateView,
  canDelete: true,
}
