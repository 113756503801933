import type { DataProvider as IDataProvider } from '@pankod/refine'
import type { BaseRecord } from '@pankod/refine/dist/interfaces'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'

import { requestBuilder } from './requestBuilder'
import type {
  CreateManyParams,
  CreateParams,
  CustomParams,
  DeleteManyParams,
  DeleteOneParams,
  GetListParams,
  HydraCollection,
  HydraItem,
  UpdateManyParams,
} from './types'

export * from './types'
export * from './hydraId'

interface DataProviderConfig {
  httpClient: AxiosInstance
  apiUrl: string
}

export function DataProvider(config: DataProviderConfig): IDataProvider {
  const { httpClient, apiUrl } = config

  function request(requestConfig: AxiosRequestConfig) {
    return httpClient.request({
      baseURL: apiUrl,
      ...requestConfig,
    })
  }

  const provider: IDataProvider = {
    async create<T>(options: CreateParams) {
      const response = await request(requestBuilder.create(options))
      return { data: processHydraItem<T>(response.data) }
    },

    async createMany<T>(options: CreateManyParams) {
      const { variables } = options
      const responses = await Promise.all(
        variables.map((values) =>
          provider.create<T>({ ...options, variables: values as any }),
        ),
      )

      return { data: responses.map((x) => x.data) }
    },

    async getList<T>(options: GetListParams) {
      const { data } = await request(
        requestBuilder.getList(processListParams(options)),
      )
      return processListResponse<T>(data)
    },

    async getOne(options) {
      const { data } = await request(requestBuilder.getOne(options))
      return { data: processHydraItem(data) }
    },

    async getMany(options) {
      const { ids } = options

      const responses = await Promise.all(
        ids.map((id) => provider.getOne({ ...options, id })),
      )
      const data = responses.map((x) => x.data as any).filter(Boolean)
      return { data, total: data.length }
    },

    async update(options) {
      const { variables } = options
      const response = await request(
        requestBuilder.update({
          ...options,
          variables: { ...variables, q: undefined },
        }),
      )
      return { data: processHydraItem(response.data) }
    },

    async updateMany<T>(options: UpdateManyParams) {
      const { ids } = options

      const responses = await Promise.all(
        ids.map((id) =>
          provider.update<T>({
            ...options,
            id,
            variables: options.variables as any,
          }),
        ),
      )
      return { data: responses.map((res) => res.data) }
    },

    async deleteOne(options: DeleteOneParams) {
      const { data } = await request(requestBuilder.delete(options))
      return {
        data: processHydraItem(data),
      }
    },

    async deleteMany<T>(options: DeleteManyParams) {
      const { ids } = options

      const responses = await Promise.all(
        ids.map((id) => provider.deleteOne<T>({ ...options, id })),
      )

      return {
        data: responses
          .map((deleteResult) => deleteResult?.data)
          .filter(Boolean),
      }
    },

    async custom(options: CustomParams) {
      const { data } = await request(requestBuilder.custom(options))
      return { data }
    },

    getApiUrl() {
      return apiUrl
    },
  }

  return provider
}

function processListParams(options: GetListParams): GetListParams {
  return {
    ...options,
    sort: options.sort?.length
      ? options.sort
      : [{ field: 'updatedAt', order: 'desc' }],
    filters: [
      { field: 'deletedAt', operator: 'null', value: true },
      ...(options.filters ?? []),
    ],
  }
}

export function processListResponse<T extends BaseRecord>(
  response: HydraCollection,
) {
  return {
    data: response['hydra:member'].map((x) => processHydraItem<T>(x)),
    total: response['hydra:totalItems'],
  }
}

function processHydraItem<T extends BaseRecord>(item: HydraItem): T {
  return { ...item, id: item['@id'] } as unknown as T
}
