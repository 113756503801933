import type { DataProvider } from '@pankod/refine'
import type { Schedule } from 'src/components/ScheduleForm'
import { ResourcePathEnum } from 'src/types/api'

import { toHydraId } from './DataProvider'

export async function manageSchedules(
  dataProvider: DataProvider,
  positionId: string,
  schedules?: Schedule[],
) {
  if (!schedules) return []

  const schedulesToCreate = schedules.filter((schedule) => !schedule.exists)
  const scheduleAlreadyCreated = schedules.filter((schedule) => schedule.exists)
  const schedulesToEdit = scheduleAlreadyCreated.filter(
    (schedule) => schedule.touched,
  )

  const createdSchedules = await createSchedules(
    dataProvider,
    positionId,
    schedulesToCreate,
  )

  updateSchedules(dataProvider, schedulesToEdit)

  const createdSchedulesIds = createdSchedules
    .map((promiseResult) => {
      if (promiseResult.status === 'fulfilled') {
        return promiseResult.value.data.id
      }
      return undefined
    })
    .filter(Boolean)

  return [
    ...createdSchedulesIds,
    ...scheduleAlreadyCreated.map((schedule) =>
      toHydraId(ResourcePathEnum.dispenser_schedules, schedule.id),
    ),
  ]
}

function createSchedules(
  dataProvider: DataProvider,
  positionId: string,
  schedules?: Schedule[],
) {
  const schedulesQueries = schedules?.map((schedule) =>
    dataProvider.create({
      resource: ResourcePathEnum.dispenser_schedules,
      variables: { ...schedule, position: positionId },
    }),
  )

  return Promise.allSettled(schedulesQueries ?? [])
}

function updateSchedules(dataProvider: DataProvider, schedules?: Schedule[]) {
  const schedulesQueries = schedules?.map((schedule) =>
    dataProvider.update({
      resource: ResourcePathEnum.dispenser_schedules,
      id: schedule.id,
      variables: schedule,
    }),
  )

  return Promise.allSettled(schedulesQueries ?? [])
}
