import {
  useTranslate,
  Card,
  Spin,
  Collapse,
  Button,
  Icons,
  Space,
  Typography,
  Input,
} from '@pankod/refine'
import type { ProfunctorState } from '@staltz/use-profunctor-state'
import classNames from 'classnames'
import type { PositionMap } from 'src/components/Map'
import { ScheduleForm } from 'src/components/ScheduleForm'
import { useGeoCoding } from 'src/libs/useGeoCoding'

import type { ExtendedPosition } from '.'

import './style.less'

interface Props {
  positionsStore: ProfunctorState<PositionMap<ExtendedPosition>>
  position?: ExtendedPosition
  active?: boolean
  deletePosition?: (id: string) => void
}
export function PositionCard(props: Props) {
  const { positionsStore, position, active, deletePosition } = props
  const translate = useTranslate()

  const { reverse } = useGeoCoding()

  if (!position) return null

  const currentPositionStore = positionsStore.promap(
    // Getter
    (state) => state[position.id],
    // Setter
    (newPosition, state) => {
      return {
        ...state,
        [position.id]: newPosition,
      }
    },
  )

  function setManualCoordinate(value: { latitude: number; longitude: number }) {
    const latitude = Number(value.latitude)
    const longitude = Number(value.longitude)

    currentPositionStore.setState((prevPosition) => {
      return {
        ...prevPosition,
        latitude,
        longitude,
      }
    })
  }

  async function getAdress() {
    if (!position) return null

    currentPositionStore.setState((prevPosition) => {
      return {
        ...prevPosition,
        isLoading: true,
      }
    })
    const geoCodingResult = await reverse({
      lat: position.latitude,
      lon: position.longitude,
    })
    const adresse = geoCodingResult.data.features[0]?.properties

    currentPositionStore.setState((prevPosition) => {
      return {
        ...prevPosition,
        city: adresse?.city,
        postalCode: adresse?.postcode,
        fullAddress: adresse?.label,
        isLoading: false,
      }
    })
  }

  const scheduleStore = currentPositionStore.promap(
    (state) => state.schedules ?? [],
    (newSchedules, state) => {
      return {
        ...state,
        schedules: newSchedules,
      }
    },
  )

  const longitudeInputId = `longitude-${position.id}`
  const latitudeInputId = `latitude-${position.id}`

  return (
    <div className={classNames('AtePositionCard', active && '-active')}>
      <Card>
        <Spin spinning={Boolean(position.isLoading)}>
          <Card.Meta
            title={
              <div className="AtePositionTitleContainer">
                <span className="AtePositionTitleText">
                  {position.fullAddress || '-'}
                </span>
                {deletePosition && position && (
                  <Button
                    danger
                    onClick={(e) => {
                      e.stopPropagation()
                      deletePosition(position.id)
                    }}
                    icon={<Icons.DeleteOutlined />}
                  />
                )}
              </div>
            }
            description={
              <Space>
                <div>
                  {translate('dispenserPositions.fields.latitude')}
                  <Input
                    id={latitudeInputId}
                    value={position.latitude}
                    type="number"
                    onChange={(event) => {
                      if (!Number.isNaN(event.target.valueAsNumber)) {
                        setManualCoordinate({
                          latitude: event.target.valueAsNumber,
                          longitude: position.longitude,
                        })
                      }
                    }}
                    onBlur={(event) => {
                      if (event.relatedTarget?.id !== longitudeInputId) {
                        getAdress()
                      }
                    }}
                  />
                </div>
                <div>
                  {translate('dispenserPositions.fields.longitude')}
                  <Input
                    id={longitudeInputId}
                    value={position.longitude}
                    type="number"
                    onChange={(event) => {
                      if (!Number.isNaN(event.target.valueAsNumber)) {
                        setManualCoordinate({
                          longitude: event.target.valueAsNumber,
                          latitude: position.latitude,
                        })
                      }
                    }}
                    onBlur={(event) => {
                      if (event.relatedTarget?.id !== latitudeInputId) {
                        getAdress()
                      }
                    }}
                  />
                </div>
              </Space>
            }
          />
        </Spin>
      </Card>
      <Collapse bordered={false}>
        <Collapse.Panel
          key="1"
          header={
            <Space style={{ width: '100%', justifyContent: 'space-between' }}>
              {translate(
                position.schedules && position.schedules.length > 0
                  ? 'dispenserSchedule.personalized'
                  : 'dispenserSchedule.default',
              )}
              <Typography.Text type="secondary">
                {translate('components.map.info.editSchedule')}
              </Typography.Text>
            </Space>
          }
        >
          <ScheduleForm scheduleStore={scheduleStore} />
        </Collapse.Panel>
      </Collapse>
    </div>
  )
}
