import { useForm, Create, Form, Input } from '@pankod/refine'
import type { Config } from 'src/types/api'

export function CreateView() {
  const { formProps, saveButtonProps } = useForm<Config>()

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item label="Title" name="title">
          <Input />
        </Form.Item>
      </Form>
    </Create>
  )
}
