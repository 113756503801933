import { ad } from './Ad'
import { client } from './Client'
import { config } from './Config'
import { dispenser } from './Dispenser'
import { item } from './Item'
import { promotion } from './Promotion'
import { report } from './Report'
import { mobileUser, user } from './User'

export const resources = [
  dispenser,
  ad,
  client,
  config,
  item,
  promotion,
  user,
  mobileUser,
  report,
]
