// THIS FILE IS AUTO-GENERATED BY FIGMAGIC. DO NOT MAKE EDITS IN THIS FILE! CHANGES WILL GET OVER-WRITTEN BY ANY FURTHER PROCESSING.

const fontFamilies = {
 "label": "Open Sans",
 "button": "Open Sans",
 "microbutton": "Open Sans",
 "microcopyBold": "Open Sans",
 "microcopy": "Open Sans",
 "bodySBold": "Open Sans",
 "bodyS": "Open Sans",
 "bodyM": "Open Sans",
 "bodyL": "Open Sans",
 "headingXs": "Montserrat",
 "headingS": "Montserrat",
 "headingM": "Montserrat",
 "headingL": "Montserrat"
} as const;

export default fontFamilies;