import type { AxiosInstance } from 'axios'
import axios from 'axios'
import { createContext, useContext } from 'react'

interface Params {
  getAccessToken: () => Promise<string | undefined>
  baseURL: string
}

export function HTTPClient(params: Params) {
  const { getAccessToken, baseURL } = params
  const httpClient = axios.create({
    baseURL,
  })

  httpClient.interceptors.request.use(async (config) => {
    const token = await getAccessToken()
    if (!token) {
      return config
    }

    return {
      ...config,
      headers: {
        SHOWDELETED: false,
        ...config.headers,
        Authorization: `Bearer ${token}`,
      },
    }
  })

  return httpClient
}

export const HttpClientContext = createContext<AxiosInstance | undefined>(
  undefined,
)

export function useHttpClient(): AxiosInstance {
  const httpClient = useContext(HttpClientContext)

  if (!httpClient) throw new Error('Please provide an HttpClient')

  return httpClient
}
