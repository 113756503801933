import { Button, Space, useTranslate } from '@pankod/refine'
import ColorExtension from '@tiptap/extension-color'
import TextStyle from '@tiptap/extension-text-style'
import type { Editor } from '@tiptap/react'
import { EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Color from 'color'
import { forwardRef } from 'react'

import './style.css'

interface ColorDefinition {
  name: string
  hex: `#${string}`
}
const COLORS: ColorDefinition[] = [
  {
    name: 'orange',
    hex: '#ED6F1E',
  },
  {
    name: 'red',
    hex: '#E73432',
  },
  {
    name: 'violet',
    hex: '#811E5E',
  },
]

interface TextEditorProps {
  value?: string
  onChange?: (value: string) => void
  readonly?: boolean
}
export const TextEditor = forwardRef<HTMLDivElement, TextEditorProps>(
  function TextEditor(props, ref) {
    const { value, onChange, readonly } = props

    const editor = useEditor({
      extensions: [
        StarterKit.configure({
          heading: {
            levels: [1, 2],
          },
        }),
        TextStyle,
        ColorExtension,
      ],
      content: value,
      editable: !readonly,
      editorProps: {
        attributes: {
          class: 'ant-input',
        },
      },
      onUpdate(updateProps) {
        let newValue = updateProps.editor.getHTML()
        COLORS.forEach((color) => {
          newValue = newValue.replace(
            Color(color.hex).rgb().toString(),
            color.hex,
          )
        })
        onChange?.(newValue)
      },
    })

    if (readonly) {
      return <EditorContent editor={editor} />
    }
    if (!editor) return null

    return (
      <div
        ref={ref}
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
        }}
      >
        <Space>
          <HeadingButton editor={editor} level={1} />
          <HeadingButton editor={editor} level={2} />
          {COLORS.map((color) => (
            <ColorButton key={color.name} color={color} editor={editor} />
          ))}
        </Space>
        <EditorContent editor={editor} />
      </div>
    )
  },
)

interface HeadingButtonProps {
  editor: Editor
  level: 1 | 2
}
function HeadingButton(props: HeadingButtonProps) {
  const { editor, level } = props

  const translate = useTranslate()

  return (
    <Button
      type={editor.isActive('heading', { level }) ? 'primary' : 'default'}
      onClick={() => editor.chain().focus().toggleHeading({ level }).run()}
    >
      {translate(`editor.buttons.heading.${level}`)}
    </Button>
  )
}

interface ColorButtonProps {
  editor: Editor
  color: ColorDefinition
}
function ColorButton(props: ColorButtonProps) {
  const { editor, color } = props

  const translate = useTranslate()

  function getIsActive() {
    if (editor?.isActive('textStyle', { color: color.hex })) {
      return true
    }
    if (
      editor?.isActive('textStyle', {
        color: Color(color.hex).rgb().toString(),
      })
    ) {
      return true
    }

    return false
  }

  function onClick() {
    if (getIsActive()) {
      editor?.chain().focus().unsetColor().run()
      return
    }
    editor?.chain().focus().setColor(color.hex).run()
  }

  return (
    <Button
      onClick={onClick}
      style={
        getIsActive()
          ? {
              color: 'white',
              borderColor: color.hex,
              backgroundColor: color.hex,
            }
          : {
              color: color.hex,
              borderColor: color.hex,
            }
      }
    >
      {translate(`editor.buttons.color.${color.name}`)}
    </Button>
  )
}
